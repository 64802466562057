import type { RoleQueryParam } from '@lib/types/RoleQueryParam'
import { removeUndefinedKeys } from '@lib/utilities/object/removeUndefinedKeys'
import type { UrlObject } from 'url'
import urlWithQueryParams from './helpers/urlWithQueryParams'

export const WWW_WITHPOWER_COM = 'www.withpower.com'
export const POWER_HOMEPAGE = `https://${WWW_WITHPOWER_COM}`

export type Url = string | UrlObject

export enum RoutePrefix {
  ABOUT = '/about',
  CENTER = '/center',
  CMS_ADMIN = '/cms-admin',
  DIRECTORY_TOPIC = '/clinical-trials',
  DOCTOR = '/doctors',
  GUIDES = '/guides',
  PARTNER_TRIALS = '/partner-trials',
  PATIENTS = '/p',
  OPENGRAPH_SHORTCODE = '/og',
  REDIRECTS = '/r',
  SHARE_REGISTRY_PROFILE = '/share-registry-profile',
  SHORTCODE = '/go',
  SPECIALISTS = '/specialists',
  TREATMENTS = '/t',
  TRIAL = '/trial',
  TRIAL_DIRECTORY = '/trial-directory',
  TRIAL_SITE = '/site',
  TRIAL_PROS = '/s',
}

const Routes = {
  aboutCatherineHall: '/about/catherine-hall',
  aboutMichaelGill: '/about/michael-gill',
  aboutUs: '/about-us',
  achievingRepresentationWhitePaper: '/white-papers/achieving-representation',
  clinicalTrialsUs: '/clinical-trials-across-the-us',
  contactUs: '/contact-us',
  dataForDiverseRecruitment: '/data-for-diverse-recruitment-with-data',
  directoryTopicsRoot: RoutePrefix.DIRECTORY_TOPIC,
  forProviders: '/for-providers',
  forResearchCoordinators: '/for-research-coordinators',
  forSponsors: '/for-sponsors',
  foundingStory: '/founding-story',
  foundingStoryUpdate: '/founding-story-update',
  guidesCysticFibrosis: '/guides/cystic-fibrosis',
  guidesGutHealth: '/guides/gut-health',
  guidesMostCommonGeneticDisorders: '/guides/genetic-disorders',
  guidesSkinConditions: '/guides/skin-conditions',
  home: '/',
  longCovid: '/long-covids-impact-on-the-workforce',
  mapSearchExperiment: '/map-search/depression/new-york',
  medicalFacts: '/medical-facts-who-do-you-trust',
  modernFertility: '/modern-fertility',
  murrayCmo: '/murray-abramson-appointed-chief-medical-officer',
  nearMe: '/clinical-trials',
  openBetaForSites: '/open-beta-power-for-sites',
  pageNotFound: '/404',
  partners: (slug: string) => `/partners/${slug}`,
  partnersEmbed: (slug: string) => `/partners/${slug}/embed`,
  partnersLoader: (slug: string) => `/partners/${slug}/loader`,
  privacyPolicy: '/privacy-policy',
  scholarships: '/scholarships',
  search: '/clinical-trials/search',
  security: '/security',
  signIn: ({ redirect }: { redirect?: string } = {}) =>
    urlWithQueryParams(
      '/sign-in',
      removeUndefinedKeys({ redirect }),
    ).toString(),
  signInEmailSent: '/sign-in/email-sent',
  signInLinkExpired: '/sign-in/link-expired',
  signInViaLink: ({
    redirect, // of the form /p/apply-package or otherwise
    token,
  }: {
    redirect?: string[] | Url
    token: string
  }) => `/sign-in/via-link/${token}${redirect ?? [].join('/')}`,
  signInWithEmail: ({
    email,
    redirect,
  }: {
    email: string
    redirect?: string
  }) =>
    urlWithQueryParams(
      `/sign-in/with-email/${email}`,
      removeUndefinedKeys({
        redirect,
      }),
    ).toString(),
  signUp: (role?: RoleQueryParam) =>
    role ? `/sign-up?role=${role}` : '/sign-up',
  termsOfService: '/terms-of-service',
  trialDirectoryPage: '/trial-directory',
  trialDirectoryRoot: '/trials',
  universityOfChicagoInternship: '/university-of-chicago-internship',
  unsubscribe: '/unsubscribe',
}

export default Routes
