import { classMerge } from '@components/utilities/classMerge'
import type { ColorGroup } from '@lib/colors/colors'
import {
  getColorHoverStates,
  getColorVariant,
  type ColorGroupVariant,
} from '@lib/utilities/colors/getColorVariant'
import { reactNodeToString } from '@lib/utilities/reactNodeToString'
import type { ReactNode } from 'react'
import { typographyStyles } from '../Text/fontStyles'
import { IconButton } from './IconButton'
import { LinkButton } from './LinkButton'

interface ButtonBaseProps {
  accent?: boolean
  ariaLabel?: string
  className?: string
  color?: ColorGroup
  disabled?: boolean
  form?: string
  id?: string
  onClick?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined
  type?: 'button' | 'submit'
  variant?: ColorGroupVariant
}

// `children` and `value` are mutually exclusive
export type ButtonProps =
  | (ButtonBaseProps & {
      children?: never
      value?: ReactNode
    })
  | (ButtonBaseProps & {
      children?: ReactNode
      value?: never
    })

export default function Button({
  accent = false,
  ariaLabel,
  children,
  className,
  color,
  disabled = false,
  form,
  id,
  onClick,
  type = 'button',
  value,
  variant,
}: ButtonProps) {
  const colorVariant = variant ?? (disabled || accent) ? 'accent' : 'filled'

  const colorClasses = getColorVariant(color ?? 'primary', colorVariant)
  const hoverClasses = getColorHoverStates(color ?? 'primary', colorVariant)

  const textValue = reactNodeToString(value)

  const buttonStyles = classMerge(
    typographyStyles.button,
    `flex items-center justify-center no-underline`,
    'h-12 w-[160px]',
    'cursor-pointer rounded-md p-0 disabled:cursor-not-allowed',
    hoverClasses,
    colorClasses,
    className,
  )

  return (
    <button
      aria-label={ariaLabel ?? textValue}
      className={buttonStyles}
      disabled={disabled}
      form={form}
      id={id}
      onClick={onClick}
      tabIndex={0}
      type={type}
    >
      {value ? value : children}
    </button>
  )
}

Button.Link = LinkButton
Button.Icon = IconButton
